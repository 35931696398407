<template>
  <div id="contact" class="container">
    <h1 class="title">Contact Us</h1>
    <div class="row">
      <div class="col-12">
        <GmapMap
            style="height: 450px;z-index: -1"
            :center="{lat:3.1913678, lng:101.6512804}"
            :zoom="16"
            :options="mapOptions"
        >
          <GmapMarker
              :position="{lat:3.1913678, lng:101.6512804}"
              :clickable="true"
              :draggable="true"
          />
        </GmapMap>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4">
        <img src="/images/dining-area.png" style="width: 100%"/>
      </div>
      <div class="col-12 col-md-8">
        <section class="row pt-0">
          <div class="col-12">
            <h2 class="no-border p-0 mt-4 mt-sm-0">
              {{ contact.title }}
            </h2>
            <p>
              {{ contact.description }}
            </p>
            <ul class="contactInfo">
              <li>
                <img src="/images/phone.png" style="width: 20px"/> <span>{{ contact.phone_no }}</span>
              </li>
              <li>
                <img src="/images/mail.png" style="width: 20px;margin-top:4px;"/> <span>{{ contact.email }}</span>
              </li>
              <li>
                <img src="/images/home.png" style="width: 20px"/> <span v-html="contact.address"></span>
              </li>
              <li>
                <img src="/images/time.png" style="width: 20px"/> <span>{{ contact.time }}</span>
              </li>
            </ul>

            <div class="row mt-3">

            </div>
          </div>
        </section>
        <section class="row mt-0 mt-md-3">
          <div class="col-12">
            <h2 class="no-border p-0">
              Contact Us
            </h2>
            <form action="#" class="contactForm">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="form.firstName" class="form-control" placeholder="First Name"/>
                  </div>
                  <div class="form-group">
                    <input type="text" v-model="form.email" class="form-control" placeholder="Email Address"/>
                  </div>
                  <div class="form-group" style="position: relative">
                    <select class="form-control" v-model="form.propertyType">
                      <option v-for="type in propertyTypes" :value="type" :key="type">{{ type }}</option>
                    </select>
                    <img class="img-fluid form-arrow-down-img" src="/images/icons/down_arrow.svg">
                  </div>
                  <div class="form-group" style="position: relative">
                    <select class="form-control" v-model="form.propertyStatus">
                      <option v-for="status in propertyStatuses" :value="status" :key="status">{{ status }}</option>
                    </select>
                    <img class="img-fluid form-arrow-down-img" src="/images/icons/down_arrow.svg">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="form.lastName" class="form-control" placeholder="Last Name"/>
                  </div>
                  <div class="form-group">
                    <input type="text" v-model="form.phone" class="form-control" placeholder="Phone No."/>
                  </div>
                  <div class="form-group">
                    <label>Message</label>
                    <textarea v-model="form.message" class="form-control"></textarea>
                  </div>
                </div>
              </div><!--/.row-->
              <div class="row">
                <div class="col-12">
                  <input type="submit" class="btn" value="Submit">
                </div>
              </div><!--/.row-->
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Contact Us'
  },
  data () {
    return {
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      form : {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        message: null,
        propertyType: 'Apartment',
        propertyStatus: 'New Unit',
        propertyAddress: null,
        budget: 0,
        idea: null,
      },
      propertyTypes: [
          'Apartment',
          'Condo',
          'Terrace',
          'Semi-D',
          'Bungalow',
          'Shophouse',
          'Commercial',
      ],
      propertyStatuses: [
          'New Unit',
          'Resale Unit',
          'Existing Unit',
          'Under Construction'
      ],
      contact: ''
    }
  },
  created() {
    this.axios.get(this.$hostname + '/api/contact', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
      }).then(response => {
        this.contact = response.data.data.attributes
    })
  }
}
</script>
